@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    /* <===Button Base===> */
    button.btn{
        @apply
        

        /* Transition Function */
        transition-all

        /* <---Hover--> */
        hover:cursor-pointer

        /* <---Disabled--> */
        disabled:hover:cursor-default
    }

    button.btn.outline-b{
        @apply
        /* Border */
        border border-blue-500 rounded-md

        /* Background */
        bg-white

        /* <---Hover--> */
        hover:text-white hover:ring-2 hover:bg-blue-500 hover:border-blue-700 hover:shadow-md 

        /* <---Focus--> */ 
        focus:bg-blue-500 focus:border-blue-700 focus:text-white

        /* <---Disabled--> */
        disabled:bg-gray-200 disabled:text-gray-800 disabled:hover:shadow-none disabled:hover:border-transparent disabled:hover:ring-0 disabled:border-gray-300 
    }

    button.btn.tab{
        @apply
        /* Border */
        border border-b-0
    }

    button.btn.tab.active{
        @apply
        bg-blue-100
    }

    button.btn.primary{
        @apply
        /* Border */
        border border-blue-500 rounded-md

        /* Background */
        bg-blue-500

        /* Text */
        text-gray-200 text-center

        /* <---Hover--> */
        hover:text-white hover:bg-blue-700 hover:border-blue-900 hover:shadow-md hover:cursor-pointer

        /* <---Focus--> */ 
        focus:bg-blue-700 focus:border-blue-950 focus:text-white

        /* <---Disabled--> */
        disabled:bg-blue-300 disabled:text-gray-800 disabled:hover:shadow-none disabled:cursor-default
    }

    /* <===Input Base===> */
    input.input{
        @apply
        /* Border */
        border border-blue-300 rounded-md

        /* Text */
        text-center

        /* Transition Function */
        transition-all

        /* <---Hover--> */
        hover:ring-2 hover:ring-blue-500

        /* <---Focus--> */
        focus:outline-none

        /* <---Invalid--> */
        invalid:border-red-500 invalid:bg-red-100
        invalid:placeholder:text-red-300

    }

    /* <===Select Base===> */
    select.select{
        @apply
        /* Border */
        border border-blue-300 rounded-md

        /* Background */
        bg-slate-100

        /* Text */
        text-center

        /* Transition Function */
        transition-all

        /* <---Hover--> */
        hover:ring-2 hover:ring-blue-500

        /* <---Focus--> */
        focus:border-blue-950

        /* <---Focus Within--> */
        focus-within:border-blue-950

        /* <---Invalid--> */
        invalid:border-red-500 invalid:bg-red-100

    }
}